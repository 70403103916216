<template>
  <v-container style="max-width: 1500px; max-height: 750px">
    <v-row class="justify-center elevation-4">
      <v-col>
        <v-responsive :aspect-ratio="2/1">
          <span id="puzzle-main-webgl"></span>
        </v-responsive>
      </v-col>
    </v-row>

    <v-row style="max-width: 1000px">
      <v-col cols="6" md="3" class="justify-start py-0 text-left">
        <v-list-item>
          <v-list-item-content class="py-1 pl-0">
            <v-list-item-subtitle style="font-family: 'Tourney', cursive; max-height: 14px;" >
              time:
            </v-list-item-subtitle>
            <v-list-item-title class="pl-10">
              <span id="timer_space" style="font-family: 'Tourney', cursive;font-size: 250%;"></span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" md="2" class="px-0 pt-4">
        <v-btn
            class="mt-0 mx-1 white--text"
            :disabled="isSuspending"
            :color="button.color"
            @click="suspend"
            v-text="`一時停止`"
        >{{ button.desc }}</v-btn>
        <v-btn
            class="mt-0 mx-0 white--text"
            :disabled="!isSuspending"
            :color="button.color"
            @click="suspend"
            v-text="`再開`"
        ></v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="5" md="2" class="pt-1">
        <v-btn
            dark
            small
            block
            class="mt-0 mx-0"
            color="gray"
            @click="toSelect"
        >
          あきらめる
        </v-btn>
        <v-btn
            dark
            small
            block
            class="mt-1 mx-0"
            color="gray"
            @click="toHome"
        >
          ホームにもどる
        </v-btn>
      </v-col>
    </v-row>

    <div class="text-center">
      <v-dialog
          v-model="clearDialog"
          width="600"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">やったー！</v-card-title>
          <v-card-text class="text-h5 pa-3">
            <div> クリア時間☞ {{ clearTime }}秒 </div>
            <v-btn
                class="white--text text-capitalize theme--dark ml-1"
                v-for="button in clearButtons"
                :key="button.click"
                :color="button.color"
                v-text="button.desc"
                @click="switchButton(button.click)"
            ></v-btn>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="clearDialog = !clearDialog"
            >
              とじる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog
          v-model="gameOverDialog"
          width="600"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">ざんねん！</v-card-title>
          <v-card-text class="text-h5 pa-3">
            <div>正しい場所ではありませんでした！</div>
            <v-btn
                class="white--text text-capitalize theme--dark ml-1"
                v-for="button in gameOverButtons"
                :key="button.click"
                :color="button.color"
                v-text="button.desc"
                @click="switchButton(button.click)"
            ></v-btn>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="gameOverDialog = !gameOverDialog"
            >
              とじる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import * as Game from './puzzle'
import {queryParser} from "./queryManager";

export default {
  name: "Play",
  components: {
  },
  data() {
    return {
      button: {
        color:'blue'
      },
      clearDialog: false,
      gameOverDialog: false,
      isSuspending: false,
      clearButtons: [
        {'click': 'twitter', 'desc': 'Twitterでみせびらかす', 'color': 'blue'},
        {'click': 'toSelect', 'desc': '別の地図であそぶ', 'color': 'gray'},
        {'click': 'toHome', 'desc': 'ホームにもどる', 'color': 'gray'},
      ],
      gameOverButtons: [
        {'click': 'reload', 'desc': 'やりなおす', 'color': 'green'},
        {'click': 'toSelect', 'desc': '別の地図であそぶ', 'color': 'gray'},
        {'click': 'toHome', 'desc': 'ホームにもどる', 'color': 'gray'},
      ],
    }
  },
  methods: {
    suspend: function(){
      if (Game.isStarted()) {
        if (this.isSuspending) {
          Game.restart()
          this.isSuspending = !this.isSuspending
        } else {
          Game.suspend()
          this.isSuspending = !this.isSuspending
        }
      }
    },
    switchButton: function(e){
      switch (e){
        case 'twitter':
          this.openTwitter()
          break
        case 'toHome':
          this.toHome()
          break
        case 'toSelect':
          this.toSelect()
          break
        case 'reload':
          this.puzzleReload()
          break
      }
    },
    toSelect: function(){
      const query = queryParser()
      const status = {
        zoom: parseInt(query['zoom']),
        center: [parseFloat(query.position.y), parseFloat(query.position.x)],
        pcs: this.$store.getters.getPcsList.indexOf(query.pieces.toString()),
      }
      this.$store.dispatch('updateMapStatus', status)
      this.$router.push('/mapuzzle/select')
    },
    openTwitter: function(){
      const status = queryParser()
      const sudden = status.isSudden ? ' ☠サドンデスモード☠ ' : ''
      const text = `${status.pieces}ピース の地図る${sudden}を ${this.clearTime}秒 でクリアしました！
      おなじマップであそぶ☟`
      const hashtags = "地図る,Mapuzzle";
      const url = encodeURIComponent(location.href)
      window.open("https://twitter.com/share?text=" + text + "&hashtags=" + hashtags + "&url=" + url);
    },
    puzzleReload: function(){location.reload()},
    toHome: function(){this.$router.push('/mapuzzle')},
  },
  mounted: function(){
    Game.startPuzzle()
  },
  computed: {
    cleared() {return this.$store.getters.getCleared},
    gameOver() {return this.$store.getters.getGameOver},
    clearTime() {return this.$store.getters.getClearTime},
  },
  watch: {
    cleared() {this.clearDialog = true},
    gameOver() {this.gameOverDialog = true},
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Tourney:ital,wght@1,900&display=swap');

#puzzle-main-webgl {
  touch-action: none;
}

</style>
