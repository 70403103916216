import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
const { v4: uuidv4 } = require('uuid');

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    multiplayer: {
      wsPath: null,
      passphrase: null,
      ownerID: null,
    },
    roomManagerUrl: process.env.VUE_APP_API_WS_ROOMMANAGER,
    gameServerUrl: process.env.VUE_APP_API_WS_GAMESERVER,
    clientID: localStorage.getItem('clientID') || uuidv4(),
    isStarted: false,
    isCleared: false,
    isGameOver: false,
    isSudden: false,
    clearTime: 0,
    listeners: [],
    mapStatus: {
      zoom: 10,
      center: [35.6812, 139.7665],
      pcs: '1',
      map: 'gsi',
    },
    pcsList: [
      '12',
      '48',
      '192',
      '300',
      '1200'],
  }
};

export default new Vuex.Store({
  state: getDefaultState(),
  getters: {
    getCleared: state => {
      return state.isCleared
    },
    getGameOver: state => {
      return state.isGameOver
    },
    getStarted: state => {
      return state.isStarted
    },
    getClearTime: state => {
      return state.clearTime
    },
    getMapStatus: state => {
      return state.mapStatus
    },
    getPcsList: state => {
      return state.pcsList
    },
    getMultiplayer: state => {
      return state.multiplayer
    },
    getClientID: state => {
      return state.clientID
    },
    getIsSudden: state => {
      return state.isSudden;
    },
  },
  mutations: {
    invertCleared (state) {
      state.isCleared = !state.isCleared
    },
    invertStarted (state) {
      state.isStarted = !state.isStarted
    },
    invertGameOver (state) {
      state.isGameOver = !state.isGameOver
    },
    setClearTime (state, time) {
      state.clearTime = time
    },
    setMapStatus (state, status) {
      state.mapStatus = status
    },
    setIsSudden: (state, status) => {
      state.isSudden = status;
    },
    setOwnerID: (state, status) => {
      state.multiplayer.ownerID = status;      
    },
    setWSPath: (state, status) => {
      state.multiplayer.wsPath = status;
    },
    setPassphrase: (state, status) => {
      state.multiplayer.passphrase = status;
    },
    addListener: (state, status) => {
      const listener = document.addEventListener(status.event, status.handler);
      state.listeners.push(listener);
    },
    resetListener: (state) => {
      state.listeners.forEach(listener => {
        document.removeEventListener(listener);
      });
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    gameStart (context) {
      context.commit('invertStarted')
    },
    gameClear (context) {
      context.commit('invertCleared')
    },
    gameOver (context) {
      context.commit('invertGameOver')
    },
    clearTime (context, time) {
      context.commit('setClearTime', time)
    },
    updateMapStatus (context, status) {
      context.commit('setMapStatus', status)
    },
    revertMapStatus (context) {
      const status = {
        zoom: 10,
        center: [35.6812, 139.7665],
        pcs: '1',
        map: 'gsi',
      }
      context.commit('setMapStatus', status)
    },
  },
  plugins: [createPersistedState()],
})
