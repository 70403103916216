<template>
  <v-container style="max-width: 1000px; max-height: 750px">
    <v-row class="justify-center elevation-4">
      <v-col>
        <v-responsive :aspect-ratio="4/3">
          <l-map ref="map" :zoom="zoom" :center="center" id="lMap">
            <l-control-layers position="topright"></l-control-layers>
            <l-tile-layer
                v-for="tileProvider in tileProviders"
                :key="tileProvider.name"
                :name="tileProvider.name"
                :visible="tileProvider.visible"
                :url="tileProvider.url"
                :attribution="tileProvider.attribution"
                layer-type="base"/>
          </l-map>
        </v-responsive>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4" class="pr-0">
        <v-slider
            :tick-labels="pcsList"
            v-model="pcs"
            min="0"
            max="4"
            label="ピース数"
        ></v-slider>
      </v-col>
      <v-col cols="auto">
        <v-btn
            dark
            class="mt-3 mx-1"
            v-for="button in buttons"
            :key="button.click"
            :color="button.color"
            @click="puzzleStart(button.click)"
        >{{ button.desc }}</v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2">
        <v-btn
            dark
            small
            block
            class="mt-0 mx-0"
            color="gray"
            @click="revertMap"
        >
          もとにもどす
        </v-btn>
        <v-btn
            dark
            small
            block
            class="mt-1 mx-0"
            color="gray"
            @click="toHome"
        >
          ホームにもどる
        </v-btn>
      </v-col>
    </v-row>

    <HowTo></HowTo>
  </v-container>
</template>

<script>
import { LMap, LTileLayer, LControlLayers } from 'vue2-leaflet';
import { queryEncoder } from "./queryManager";
import HowTo from "./HowTo";

export default {
  name: "Select",
  components: {
    LMap,
    LTileLayer,
    LControlLayers,
    HowTo,
  },
  data() {
    const mapStatus = this.$store.getters.getMapStatus
    const mapPcsList = this.$store.getters.getPcsList
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: mapStatus.zoom,
      center: mapStatus.center,
      pcs: mapStatus.pcs,
      pcsList: mapPcsList,
      buttons: [
        {'click': 'normal', 'desc': 'ふつうモード', 'color': 'green'},
        {'click': 'sudden', 'desc': 'サドンデスモード', 'color': 'red'},
      ],
      tileProviders: [
        {
          name: '地理院地図',
          visible: true,
          url: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
          attribution:
              "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院地図</a>",
        },
        {
          name: '地理院淡色地図',
          visible: false,
          url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
          attribution:
              "<a href='http://portal.cyberjapan.jp/help/termsofuse.html' target='_blank'>地理院淡色地図</a>",
        },
        {
          name: 'OpenStreetMap',
          visible: false,
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
      ],
    };
  },
  methods: {
    puzzleStart: function(e){
      switch (e){
        case 'normal':
          this.start(false)
          break
        case 'sudden':
          this.start(true)
          break
      }
    },
    switchPcs: function(e){return this.pcsList[e]},
    start: function(isSudden){
      const q = queryEncoder(this.$refs.map.mapObject, this.switchPcs(this.pcs), isSudden)
      this.$router.push(`/mapuzzle/play${q}`)
    },
    toHome: function(){this.$router.push('/mapuzzle')},
    revertMap: function(){
      this.$store.dispatch('revertMapStatus')
      const mapStatus = this.$store.getters.getMapStatus
      this.pcs = mapStatus.pcs
      this.$refs.map.mapObject.setView(mapStatus.center, mapStatus.zoom)
    },
    saveMap: function(){
      const zoom = this.$refs.map.mapObject.getZoom()
      const center = this.$refs.map.mapObject.getCenter()
      const pcs = this.pcs

      const status = {
        zoom: zoom,
        center: center,
        pcs: pcs
      }
      this.$store.dispatch('updateMapStatus', status)
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveMap()
    next()
  },
  created() {
    window.addEventListener('beforeunload', this.saveMap)
  },
  destroyed() {
    window.addEventListener('beforeunload', this.saveMap)
  }
}
</script>

<style scoped>

</style>
