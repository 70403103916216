import Vue from 'vue'
import VueRouter from 'vue-router'
import Mapuzzle from "../views/Mapuzzle";
import MapuzzleSelect from "../views/tizuru/Select";
import MapuzzlePlay from "../views/tizuru/Play";
import MapuzzleSelectM from "../views/tizuru/SelectMultiplayer";
import MapuzzlePlayM from "../views/tizuru/PlayMultiplayer";
import MapuzzleDesc from '../views/tizuru/Description'

import store from '@/store';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/mapuzzle'
  },
  {
    path: '/mapuzzle',
    component: Mapuzzle,
    children: [
      {
        path: '/mapuzzle',
        component: MapuzzleDesc
      },
      {
        path: '/mapuzzle/select',
        component: MapuzzleSelect
      },
      {
        path: '/mapuzzle/play',
        component: MapuzzlePlay
      },
      {
        path: '/mapuzzle/multiplayer',
        component: MapuzzlePlayM,
        beforeEnter: function (to, from, next) {
          const isAuth = (store.state.multiplayer.passphrase !== null);
          if (isAuth) {
            next();
          } else {
            next('/mapuzzle');
          }
        }
      },
      {
        path: '/mapuzzle/selectm',
        component: MapuzzleSelectM,
        beforeEnter: function (to, from, next) {
          const isAuth = (
            store.state.multiplayer.ownerID !== null && 
            store.state.multiplayer.ownerID === store.state.clientID);

          if (isAuth) {
            next();
          } else {
            next('/mapuzzle');
          }
        }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
