<template>
  <v-container>
    <v-row class="justify-center">
      <v-col v-for="mode in modes" :key="mode.name" cols="auto" md="4">
        <v-card style="height: 100%">
          <span @click="mode.click" style="text-decoration: none; color: black;" text>
            <v-img
              :src="mode.img"
              class="black--text align-end"
              gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.2)"
            >
            </v-img>
            <v-card-title style="color: royalblue;">{{ mode.name }}</v-card-title>
          </span>
          <v-divider/>
          <v-card-text style="text-align: left">
            <h3>せつめい</h3>
            <p
              class="my-1 py-1"
              v-for="text in mode.desc"
              :key="text"
              v-text="text"
            ></p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialogForMulti" max-width="400px">
      <v-card>
        <v-card-title class="headline">マルチプレイ</v-card-title>
        <v-container>
          <v-text-field
            v-model="passphrase"
            label="合言葉をいれてね"
            outlined
          ></v-text-field>

          <v-row justify="center" class="mb-2">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="checkingServer">サーバーをたてる</v-btn>
            <!-- <v-spacer></v-spacer> -->
            <v-btn color="secondary" @click="joinServer">サーバーに参加</v-btn>           
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="cancel">やめる</v-btn>           
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialogForSavedata" max-width="400px">
      <v-card>
        <v-card-title class="headline">セーブデータがあります</v-card-title>
        <v-container>
          <v-row justify="center" class="mb-2">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="useSavedataToCreate">つづきから</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="purgeSavedataToCreate">はじめから</v-btn>   
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import singleImg from "../../assets/PuzzleDetail.png";

export default {
  name: "Description",
  data() {
    return {
      showDialogForMulti: false,
      showDialogForSavedata: false,
      passphrase: '',
      modes: [
        {
          name: "シングルプレイ",
          click: () => {this.$router.push('/mapuzzle/select')},
          desc: [
            "地図でパズルをしよう！",
            "ふつうモードと、プロのためのサドンデスモード！"],
          img: singleImg,
          isDisable: false
        },
        {
          name: "マルチプレイ(ベータ版)",
          click: () => {this.showDialogForMulti = true},
          desc: ["協力プレイ"],
          img: singleImg,
          isDisable: false
        }
      ],
    }
  },
  methods: {
    cancel() {
      this.showDialogForMulti = false;
    },
    purgeSavedataToCreate() {
      localStorage.removeItem('multiplayerDetail');
      this.$router.push('/mapuzzle/selectm');
    },
    purgeSavedataToJoin() {
      localStorage.removeItem('multiplayerDetail');
      this.$router.push('/mapuzzle/multiplayer');
    },
    useSavedataToCreate() {
      this.$router.push('/mapuzzle/multiplayer');
    },
    checkingServer() {
      (async () => {
        const message = JSON.stringify({
          type: 'create',
          pass: this.passphrase,
          id: this.$store.state.clientID,
        });
        // const res = JSON.parse(await this.askWS(this.$store.state.roomManagerUrl, message));
        const res = JSON.parse(await this.askWS('https://mpws.sushi334.com:8901', message));
        if (res.isError) {
          // エラー処理
          console.log(`Error! reason: ${res.errorDetail}`);
          if (res.ownerID == this.$store.state.clientID) {
            console.log('But, ownerID and clientID is same. continue.')
          } else {
            return;
          }
        } else {
          console.log(`Sucsess!`)
        }

        this.$store.commit('setWSPath', res.path);
        this.$store.state.multiplayer.passphrase = this.passphrase;
        this.$store.commit('setOwnerID', res.ownerID);


        const storagedData = JSON.parse(localStorage.getItem('multiplayerDetail'));
        if (storagedData !== null) {
          if (storagedData.ownerID === this.$store.state.multiplayer.ownerID) {
            this.showDialogForSavedata = true;
            return;
          }
        }
        
        this.purgeSavedataToCreate();
      })();
    },
    joinServer() {
      (async () => {
        const message = JSON.stringify({
          type: 'join',
          pass: this.passphrase,
          id: this.$store.state.clientID,
        });
        // const res = JSON.parse(await this.askWS(this.$store.state.roomManagerUrl, message));
        const res = JSON.parse(await this.askWS('https://mpws.sushi334.com:8901', message));
        if (res.isError) {
          // エラー処理
          console.log(`Error! reason: ${res.errorDetail}`)
          return;
        } else {
          console.log(`Sucsess!`)
        }
        this.$store.commit('setWSPath', res.path);
        this.$store.commit('setPassphrase', this.passphrase);
        this.$store.commit('setOwnerID', res.ownerID);
        this.purgeSavedataToJoin();
      })();
    },
    askWS(url, mes) {
      return new Promise((resolve, reject) => {
        const socket = new WebSocket(url);

        socket.onopen = () => {
          socket.send(mes);
        };

        socket.onmessage = (event) => {
          console.log("Received response:", event.data);
          resolve(event.data);
          socket.close();
        };

        // エラーが発生したときのイベント
        socket.onerror = (error) => {
          console.error("WebSocket error:", error);
          reject(error);
          socket.close();
        };
      });
    }
  }
}
</script>

<style scoped>

</style>
