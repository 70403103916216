import * as PIXI from 'pixi.js'
import {queryParser} from "./queryManager";
import store from '@/store'
let pixi_app
let end_flag = false
let is_suspend = false
let start_time = 0
let suspend_time = 0
let suspend_sprite
let sprites_arr = []
let borderless_arr = []

async function draw_puzzle(puzzle_size_str='1000x750', is_land_scape=true, padding=500){
    const status = queryParser()
    const isSudden = status['isSudden']
    const padding_size = is_land_scape ? {'row': padding, 'column':0} : {'row':0, 'column': padding}

    let piece_num = status.pieces
    const puzzle_size = {'row': Number(puzzle_size_str.split('x')[0]), 'column': Number(puzzle_size_str.split('x')[1])}
    const piece_size = (puzzle_size.row * puzzle_size.column / piece_num) ** 0.5

    pixi_app = await init_pixi()
    const base_arr = create_puzzle_base()

    const map_pos = {'lng': status.position.x, 'lat': status.position.y}
    let url = get_mapurl(puzzle_size_str, map_pos, status.zoom, status.map)

    let position_arr = []

    create_piece(url).then(() => {
        suspend_sprite = create_suspend_sprite()
      }
    )

    async function init_pixi() {
        const width = puzzle_size.row + padding_size.row
        const height = puzzle_size.column + padding_size.column
        let app = new PIXI.Application({
            width: width,
            height: height,
            backgroundColor: 0xFFFFFF,
        });
        let el = document.getElementById('puzzle-main-webgl');
        el.appendChild(app.view);
        app.sortableChilderen = true

        app.view.style.width = '95vw'
        app.view.style.height = 'auto'
        app.view.style['max-width'] = `${width}px`
        app.view.style['max-height'] = `${height}px`

        app.renderer.plugins.interaction.autoPreventDefault = false;
        app.renderer.view.style.touchAction = 'auto';

        return new Promise(resolve => {resolve(app)})
    }

    function create_puzzle_base(){
        const xy_d = create_base_position()

        const canvas = document.createElement('canvas')
        canvas.width = puzzle_size.row
        canvas.height = puzzle_size.column

        const ctx = canvas.getContext("2d")
        ctx.fillStyle = 'whitesmoke';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        let i = 0
        while (i < xy_d.xy.length) {
            const x = xy_d.x[i] - piece_size/2
            const y = xy_d.y[i] - piece_size/2
            ctx.strokeStyle = 'lightgray'
            ctx.strokeRect(x,y, piece_size, piece_size)
            i=(i+1)|0
        }
        const image_url = canvas.toDataURL('image/png')

        const sprite = new PIXI.Sprite.from(image_url);
        sprite.anchor.x = 0.5;
        sprite.anchor.y = 0.5;
        sprite.x = puzzle_size.row/2
        sprite.y = puzzle_size.column/2
        add_sprite([sprite])

        function create_base_position(){
            const xy_arr = []
            const x_arr = []
            const y_arr = []
            // 土台が作成される起点の座標
            const default_x = 0 + piece_size/2
            const default_y = 0 + piece_size/2
            const max_x = puzzle_size.row

            let n = 0
            let x = default_x
            let y = default_y
            while (n < piece_num) {
                const pos = [x,y]
                xy_arr.push(pos)
                x_arr.push(x)
                y_arr.push(y)

                if (x+piece_size > max_x) {
                    x = default_x
                    y += piece_size
                } else {
                    x += piece_size
                }
                n=(n+1)|0
            }

            const xy_dict = {'xy':xy_arr, 'x':x_arr, 'y':y_arr}
            return xy_dict
        }
        return xy_d
    }

    async function create_piece(map_url){
        let row = 0
        let column = 0

        const response = await fetch(map_url)
        response.ok ? map_url = await response.blob() : console.log(`HTTP-Error: ${response.status}`)
        map_url = URL.createObjectURL(map_url)
        const map_image = new Image()
        const sound = sound_init()
        map_image.addEventListener('load',function (){
            for (let i=0; i <= piece_num-1; i++){
                const texture = {'plane':convert_image_size(map_image, piece_size, row, column),
                    'bordered':draw_piece_border(map_image, piece_size, row, column)}

                let position = {'x':0, 'y':0}
                // ピースが画面外に出ないように座標を調整
                if (padding_size.row) {
                    position.x = puzzle_size.row + Math.floor(Math.random()*(padding_size.row - piece_size*2))+piece_size
                    position.y = Math.floor(Math.random()*(puzzle_size.column - piece_size*2))+piece_size
                } else {
                    position.x = Math.floor(Math.random()*(puzzle_size.row - piece_size*2))+piece_size
                    position.y = puzzle_size.column + Math.floor(Math.random()*(padding_size.column - piece_size*2))+piece_size
                }
                const sprite = create_piece_sprite(pixi_app, sound, texture.bordered, position.x, position.y, row, column, piece_size)
                const borderless_sprite = create_piece_sprite(pixi_app, sound, texture.plane, row, column, row, column, piece_size, false)

                borderless_arr.push(borderless_sprite)
                sprites_arr.push(sprite)
                if (row == puzzle_size.row - piece_size) {
                    row = 0
                    column += piece_size
                } else {
                    row += piece_size
                }
            }
            // 配列を値渡しで複製する
            const shuffled_sprites = sprites_arr.slice()
            add_sprite(shuffle_array(shuffled_sprites))
            add_sprite(borderless_arr)

            isSudden ? select_one_piece() : false
            return new Promise(resolve => {resolve()})
        })
        map_image.src = map_url
    }

    function select_one_piece() {
        const selected_sprite = sprites_arr[getRandomInt(piece_num -1)]
        collect(selected_sprite).then()
    }

    function add_sprite(sprites) {
        let i = 0
        while (i < sprites.length) {
            pixi_app.stage.addChild(sprites[i]);
            i=(i+1)|0
        }
    }

    function create_suspend_sprite() {
        const width = puzzle_size.row + padding_size.row
        const height = puzzle_size.column + padding_size.column

        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext("2d")
        ctx.strokeStyle = '#222'
        ctx.lineWidth = width > height ? width*10 : height*10
        ctx.strokeRect(0,0, width, height)

        ctx.font = '64px gothic'
        ctx.textAlign = "center"
        ctx.fillStyle = '#DDD'
        ctx.fillText('みせられないよ！', width/2, height/2)

        const image_url = canvas.toDataURL('image/png')
        let sprite = new PIXI.Sprite.from(image_url);

        sprite.anchor.x = 0;
        sprite.anchor.y = 0;
        sprite.x = 0
        sprite.y = 0
        sprite.zIndex = 5
        sprite.visible = false
        add_sprite([sprite])
        return sprite
    }

    function convert_image_size(image, size, row, column){
        const canvas = document.createElement('canvas')
        canvas.width = size
        canvas.height = size

        const ctx = canvas.getContext("2d")
        // sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight
        ctx.drawImage(image, row, column, size, size, 0, 0, size, size)

        const image_url = canvas.toDataURL('image/png')
        return image_url
    }

    function draw_piece_border(image, size, row, column){
        const canvas = document.createElement('canvas')
        canvas.width = size
        canvas.height = size

        const ctx = canvas.getContext("2d")
        ctx.drawImage(image, row, column, size, size, 0, 0, size, size)
        ctx.strokeStyle = 'black'
        ctx.strokeRect(0,0, size, size)

        const image_url = canvas.toDataURL('image/png')
        return image_url
    }

    function sound_init(){
        const ussr = new Audio(require('@/assets/sound/otoware_ussr_kokka.mp3'))
        const click1 = new Audio(require('@/assets/sound/click1.mp3'))
        const click2 = new Audio(require('@/assets/sound/click2.mp3'))
        const glass = new Audio(require('@/assets/sound/glass_broken.mp3'))

        return [ussr, click1, click2, glass]
    }

    function create_piece_sprite(app, sound, tex, x=0, y=0, l, c, size, is_bordered=true) {
        let sprite = new PIXI.Sprite.from(tex);
        sprite.x = x
        sprite.y = y

        if (is_bordered) {
            sprite.anchor.x = 0.5;
            sprite.anchor.y = 0.5;

            sprite.zIndex = 1
            // nameにピースの正しい位置情報を入れておく
            sprite.name = `${l + size / 2},${c + size / 2}`

            sprite.interactive = true
            sprite.buttonMode = true

            sprite.on('mousedown', (function(e){click_sprite(app, sprite, e)}))
            sprite.on('mouseup', (function(){release_sprite(app, sprite, base_arr.xy)}))
            sprite.on('mouseupoutside', (function(){release_sprite(app, sprite, base_arr.xy)}))
            sprite.on('mousemove', (function(){move_sprite(app, sprite, base_arr.xy)}))

            sprite.on('touchstart', (function(e){click_sprite(app, sprite, e)}))
            sprite.on('touchend', (function(){release_sprite(app, sprite, base_arr.xy)}))
            sprite.on('touchendoutside', (function(){release_sprite(app, sprite, base_arr.xy)}))
            sprite.on('touchmove', (function(){move_sprite(app, sprite, base_arr.xy)}))

        } else {
            sprite.visible = false
        }

        function click_sprite(app, sprite, event) {
            sprite.data = event.data
            // sprite.alpha = 0.5
            sprite.dragging = true

            sprite.zIndex = 5
            app.stage.sortChildren()

            // すでに記録されていたらなにもしない 記録されていなかったらタイマーを起動する
            start_time || timer_start()
        }

        function release_sprite(app, sprite, base_pos) {
            sprite.dragging = false
            sprite.data = null

            sprite.zIndex = 1
            app.stage.sortChildren()
            position_arr = update_position_arr()

            const fixed_pos = {'x': sprite.name.split(',')[0], 'y': sprite.name.split(',')[1]}
            if (fixed_pos.x == sprite.x && fixed_pos.y == sprite.y) {
                collect(sprite, sound).then()
                check_collect_pieces()
            } else {
                if (isSudden){
                  const found = base_pos.find(pos => pos[0] == sprite.x && pos[1] == sprite.y)
                  found ? finnish_puzzle(true, sound[0]) : false
                }
            }
        }
        return sprite
    }

    function update_position_arr() {
        let position_arr = sprites_arr.map(s => ('' + s.x + s.y))
        return position_arr
    }

    function move_sprite(app, sprite, base_pos) {
        if (sprite.dragging) {
            let position = sprite.data.getLocalPosition(sprite.parent)
            const x = position.x
            const y = position.y
            // 位置変更
            sprite.x = (0 <= x && x <= app.screen.width) ? x : sprite.x
            sprite.y = (0 <= y && y <= app.screen.height) ? y : sprite.y

            const snap_range = piece_size / 3

            //snapさせる処理
            const found = base_pos.find(b =>
                (b[0] + snap_range) >= x && (b[0] - snap_range) <= x &&
                (b[1] + snap_range) >= y && (b[1] - snap_range) <= y
            )
            if (found) {
                // snap先にすでにピースがあったときにはsnapしない
                if (!(position_arr.includes(`${found[0]}${found[1]}`))) {
                    sprite.x = found[0]
                    sprite.y = found[1]
                }
            }
        }
    }

    async function collect(sprite, sound=null){
      // 非表示にしてあるボーダー無しピースの場所を特定する
      const sx = ((parseFloat(sprite.name.split(',')[0]) + piece_size/2) / piece_size)
      const sy = ((parseFloat(sprite.name.split(',')[1]) + piece_size/2) / piece_size - 1) * (puzzle_size.row / piece_size)
      const sxy = sx + sy - 1
      // ボーダー無しピースを表示させてボーダーを消したように見せる
      borderless_arr[sxy].visible = true
      sprite.visible = false
      sprite.interactive = false
      sprite.buttonMode = false

      // スプライトの位置を正しい位置に矯正する とくにサドンデスモード時
      sprite.x = borderless_arr[sxy].x + piece_size/2
      sprite.y = borderless_arr[sxy].y + piece_size/2

      const rand = Math.random()
      if (sound) {
        if (rand < 0.5) {
          sound[1].play()
        } else if (rand < 0.999) {
          sound[2].play()
        } else {
          sound[3].play()
        }
      }
      return new Promise(resolve => {resolve()})
    }

    function check_collect_pieces(){
        const default_x = 0 + piece_size/2
        const default_y = 0 + piece_size/2
        const max_x = puzzle_size.row

        let finish = true
        let n = 0
        let x = default_x
        let y = default_y
        while (n < sprites_arr.length) {
            const sprite_x = sprites_arr[n].position.x
            const sprite_y = sprites_arr[n].position.y

            if (sprite_x == x && sprite_y == y) {
                if (sprite_x + piece_size > max_x) {
                    x = default_x
                    y += piece_size
                } else {
                    x += piece_size
                }
            } else {
                finish = false
                break
            }
            n=(n+1)|0
        }
        if (finish) {
            finnish_puzzle()
        }
    }

    const timer_space = document.getElementById('timer_space')
    const timer_elem = document.createElement('p')
    timer_elem.textContent = '0'
    timer_elem.id = 'timer'

    timer_space.appendChild(timer_elem)
    count(suspend_time)

    function finnish_puzzle(is_game_over=false, sound=null){
        timer_stop()
        if (is_game_over) {
            popup(is_game_over)
            notInteractiveAllPieces()
            sound.play()
        } else {
            const clearTime = timer_elem.textContent
            popup(is_game_over, clearTime)
        }
    }
}

function notInteractiveAllPieces() {
    sprites_arr.forEach(sprite => {
        sprite.interactive = false
        sprite.buttonMode = false
    })
}

function timer_start() {
    start_time = Date.now()
    return start_time
}

function timer_stop() {
    end_flag = true
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function count(suspend_time) {
    const timer_elem = document.getElementById('timer')
    if (timer_elem){
        const now_score = (Math.ceil((Date.now() - start_time + suspend_time) / 100) / 10).toFixed(1)
        setTimeout(function (){
            timer_elem.textContent = end_flag || is_suspend ? `${now_score}` : count(suspend_time)
        },5)
        return start_time ? `${now_score}` : is_suspend ? `${now_score}(中断中)` : '0'
    } else {
        return false
    }
}


function shuffle_array(arr) {
    const array = arr
    for (let i = array.length; 1 < i; i--) {
        const k = Math.floor(Math.random() * i);
        [array[k], array[i - 1]] = [array[i - 1], array[k]];
    }
    return array
}

function get_mapurl(size='800x600', pos, zoom, mapid){
    let map_url = `https://sushi334.com/maps?center=${pos.lng},${pos.lat}&zoom=${zoom}&mapid=${mapid}&size=${size}`;
    return map_url
}

function startPuzzle() {
    function init() {
        pixi_app = null
        end_flag = false
        is_suspend = false
        start_time = 0
        suspend_time = 0
        suspend_sprite = null
        sprites_arr = []
        borderless_arr = []
    }
    init()
    draw_puzzle('1000x750', true, 500).then()
}

function restart(){
    if (start_time && is_suspend && !(end_flag)) {
        sprites_arr.forEach(s => s.interactive = true)

        suspend_sprite.visible = false

        start_time = Date.now()
        is_suspend = false
        count(suspend_time)
        return true
    } else {
        return false
    }
}

function isStarted(){
    return !!(start_time && !(end_flag))
}

function suspend(){
    if (start_time && !(is_suspend) && !(end_flag)) {

        sprites_arr.forEach(s => s.interactive = false)
        // 盤面を隠すスプライトを表示
        suspend_sprite.visible = true
        // 時間
        suspend_time = Date.now() - start_time + suspend_time
        is_suspend = true
        return true
    } else {
        return false
    }
}

function popup(isGameOver, clearTime){
  if (isGameOver) {
    store.dispatch('gameOver')
  } else {
    store.dispatch('gameClear')
    store.dispatch('clearTime', clearTime)
  }
}

export { restart, suspend, startPuzzle, isStarted }
