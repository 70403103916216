function queryParser() {
  const q = location.search
  const parse = () => [...new URLSearchParams(q).entries()].reduce((obj, e) => ({...obj, [e[0]]: e[1]}), {})
  const DEFAULT_QUERY = {
    'zoom': 10,
    'sud': false,
    'map': 'osm',
    'pcs': '48',
    'lng': 35.6812,
    'lat': 139.7665
  }

  const query = q ? parse() : DEFAULT_QUERY

  const max_zoom = 1015
  const zoom = query['zoom'] <= max_zoom ? query['zoom'] : max_zoom
  const isSudden = query['sud'] === 'true' && query['sud'] ? true : false

  // おかしな名前をmapに突っ込まれても問題ないように
  let map
  let pieces
  switch (query['map']) {
    case 'gsi':
      map = 'gsi'
      break
    case 'gsi_pale':
      map = 'gsi_pale'
      break
    case 'osm':
    default:
      map = 'osm'
  }

  switch (query['pcs']) {
    case '1200':
      pieces = 1200
      break
    case '300':
      pieces = 300
      break
    case '192':
      pieces = 192
      break
    case '48':
      pieces = 48
      break
    case '12':
    default:
      pieces = 12
      break
  }
  const parsed_query = {'zoom': zoom, 'position':{'x': query['lng'], 'y': query['lat']}, 'pieces': pieces, 'map': map, 'isSudden': isSudden}
  return parsed_query
}

function queryEncoder(mapObject, pieceNum, isSudden){
  pieceNum = parseInt(pieceNum)
  const pos = mapObject.getCenter()
  const zoom = mapObject.getZoom()
  const map = document.querySelectorAll('.leaflet-control-attribution.leaflet-control a')[1].textContent
  let mapId

  switch (map) {
    case '地理院地図':
      mapId = 'gsi'
      break
    case '地理院淡色地図':
      mapId = 'gsi_pale'
      break
    case 'OpenStreetMap':
      mapId = 'osm'
      break
  }
  const status = {'zoom': zoom,
    'lat': pos.lat,
    'lng': pos.lng,
    'pieces': pieceNum,
    'map': mapId,
    'isSudden': isSudden}

  const query = `?zoom=${status.zoom}&lat=${status.lat}&lng=${status.lng}&pcs=${status.pieces}&map=${status.map}&sud=${status.isSudden}`
  return query
}

export { queryParser, queryEncoder }
