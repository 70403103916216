<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <div style="transition:none !important;">
      <v-app-bar
          clipped-left
          id="app-bar"
          color="details"
          class="mx-0 px-0"
          dense
      >
        <v-app-bar-nav-icon @click="isMenuOpen = !isMenuOpen"></v-app-bar-nav-icon>
        <v-toolbar-title>ゲームおきば</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon to="/">
          <v-icon>home</v-icon>
        </v-btn>
      </v-app-bar>

      <v-navigation-drawer
          app
          clipped
          style="text-align: left"
          v-model="isMenuOpen"
      >
        <v-list dense class="pb-0 mb-0">
          <v-list-item
              @click="isMenuOpen = !isMenuOpen"
          >
            <v-list-item-icon>
              <v-icon>close</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="`とじる`"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider/>
        <v-list>
          <v-list-item
              v-for="item in menu"
              :key="item.href"
              :to="item.href"
              link
          >
            <v-list-item-icon>
              <v-icon> {{ item.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.body"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
    <router-view></router-view>
  </v-app>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');

html {
  font-family: 'Noto Sans JP', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-y: scroll;
}

#information {
  font-family: 'Noto Sans JP', sans-serif;

  .header {
    font-family: 'Yusei Magic', sans-serif;
  }
}

.v-messages {
  width: 0;
  height: 0;
  height: 0;
}
</style>
<script>
export default {
  name: "app",
  data() {
    return {
      isMenuOpen: false,
      menu: [
        {icon: "home", body: "ホームにもどる", href: '/'},
        {icon: "map", body: "地図る", href: '/mapuzzle'},
      ],
    };
  },
};
</script>
