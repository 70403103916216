<template>
  <div>
<!--    <h1 class="header" v-text="`地図る`"></h1>-->
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'tizuru',
  }
</script>
