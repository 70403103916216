<template>
  <v-container class="justify-center">
    <v-row>
      <v-col cols="12">
        <div class="text-h3" v-text="`↓★あそびかた★↓`"></div>
        <div class="text-h3" v-text="``"></div>
      </v-col>
      <v-col>
        <span class="text-h5" v-text="`地図の範囲がそのままパズルになります`"></span>
        <v-img
          src='./img/map.png'
          max-height="300"
          aspect-ratio="0.75"
        ></v-img>
      </v-col>
      <v-col>
        <span class="text-h5" v-text="`モードを選ぶとスタート！`"></span>
        <v-img
          src='./img/puzzled.png'
          max-height="300"
          aspect-ratio="2"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HowTo"
}
</script>

<style scoped>

</style>
